import React from 'react'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'
import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
// import { rhythm } from '../utils/typography'
import './index.scss'


class BlogIndex extends React.Component {
  
  render() {
    const { data } = this.props
    console.log('props', this.props)
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const items = [{text:'first'}, {text: 'second'}, {text: 'third'}]

    // const imgTest = data.mdx.frontmatter
    // console.log('img', posts[0].node.frontmatter.featuredImg.childImageSharp.fluid)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        {/* <SEO
          title="All posts"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        /> */}
        {/* <Bio /> */}
        {/* <Image fluid={imgTest.featuredImg.childImageSharp.fluid} /> */}
        
        <div  className="container">
        <hr/>

          <div className="row">
          {posts.map(({ node }) => {
      
            const title = node.frontmatter.title || node.fields.slug
            return (
                  <div key={node.fields.slug} className="col s12 m6 l4 xl3">
                    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                      <img className="squareImg" src={node.frontmatter.featuredImg.childImageSharp.fixed.src} alt={title} /> 
                      
                      <p className="grey-text title-index">{title}</p> 
                      </Link>
                  
                    {/* <small>{node.frontmatter.date}</small> */}
                    {/* <p dangerouslySetInnerHTML={{ __html: node.excerpt }} /> */}
                  </div>
            )
          })}
          </div>
          <hr />
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const query = graphql`
  query pageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImg {
            childImageSharp {
              fixed(width: 350, height: 350) {
                src
              }
            }
          }
          }
        }
      }
    }
  }
`